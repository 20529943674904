import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Header } from 'components';
import { Layout, Content } from 'layouts';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Container = styled.div`
  background: ${props => props.theme.colors.white.white};
  max-width: ${props => props.theme.maxWidth};
  margin: 0 10vw;
  height: 100%;
  padding: 4rem;
  h3 {
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  img {
    width: 100%;
  }
  .icon {
    width: 38px;
    padding: 0;
    margin-bottom: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 2rem;
    margin: 0;
  }
`;

const Page = ({ data, location }) => {
  const { page } = data.gcms;
  const seoTitle = page.seoTitle ? page.seoTitle : page.title;

  return (
    <>
      <Layout location={location} seoTitle={seoTitle} seoDescription={page.seoDescription} seoImage={page.seoImage && page.seoImage.url}>
        <Header
          title={page.title}
          hasButton={page.hasButton}
          buttonUrl={page.buttonURL}
          buttonText={page.buttonText}
        />
        <Container>
          {/* The main page content */}
          <Content richTextContent={page.body && page.body.html} markdownContent={page.markdown} />
        </Container>
      </Layout>
    </>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string.isRequired,
        body: PropTypes.object.isRequired,
        hasButton: PropTypes.bool,
        buttonURL: PropTypes.string,
        buttonText: PropTypes.string,
        seoTitle: PropTypes.string,
        seoDescription: PropTypes.string,
        seoImage: PropTypes.object,
        markdown: PropTypes.string
      }).isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default Page;

export const postQuery = graphql`
  query($slug: String!) {
    gcms {
      page(where: {slug: $slug}) {
        slug
        title
        id
        body {
          html
          text
        }
        markdown
        hasButton
        buttonURL
        buttonText
        publishedAt
        seoTitle
        seoDescription
        seoImage {
          url
        }
      }
    }
  }
`;
